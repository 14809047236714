@import '@instabox/consumer-web-ui/dist/styles/sass-variables.scss';

.container {
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 400px;
  overflow-x: hidden;
  background-image: var(--background-image);
  background-color: var(--background-color);
}

.blendMode_backDrop {
  background-image: var(--background-image),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.23), rgba(0, 0, 0, 0.23));
  background-blend-mode: multiply;
}

.blendMode_overlay {
  background-image: var(--background-image),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
  background-blend-mode: overlay;
}

.fullscreen {
  --height: 90vh;
  min-height: calc(var(--height) - var(--header-height));
  @media screen and (min-width: $breakpoint-md) {
    --height: 100vh;
  }
}

.content {
  position: absolute;
  width: 100%;
}

.content_bottom {
  bottom: var(--gap-md);
}

.content_top {
  top: var(--gap-md);
}

.content_center {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
