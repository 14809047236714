.card {
  min-height: 100px;
  cursor: pointer;
  transition: background-color 0.15s, transform 0.3s ease;
  &:hover {
    background-color: var(--color-white);
    transform: scale(1.15);
  }
}

.card,
.link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
