@import '@instabox/consumer-web-ui/dist/styles/sass-variables.scss';

.grid {
  --max-col-w: 150px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--max-col-w), 1fr));
  justify-content: center;
  gap: var(--gap-md);
  padding-top: var(--gap-md);
  @media screen and (min-width: $breakpoint-md) {
    --max-col-w: 250px;
  }
}
