@import '@instabox/consumer-web-ui/dist/styles/sass-variables.scss';

.container {
  position: relative;
  background-color: var(--color-better-blush);
  padding: var(--spacing-zl) 0;
  overflow-x: hidden;
  & + & {
    padding-top: 0;
  }
}

.image {
  > span {
    border-radius: 20px;
  }
  @media screen and (min-width: $breakpoint-lg) {
    max-width: 595px;
    max-height: 568px;
  }
  &.rtl {
    margin-right: 0;
    margin-left: auto;
  }
}

.inlineImage > span {
  border-radius: 20px;
}

.animation {
  background-color: var(--color-silly-salmon);
  border-radius: 20px;
  padding: var(--spacing-md) var(--spacing-xs) 0;
  overflow-y: hidden;
}
